export const tagList = [
  {
    id: 1,
    category: "Film",
    english: "Friendship",
    portuguese: "Amizade",
    czech: "Přátelství",
    deutsch: "Freundschaft",
    finnish: "Ystävyys",
    croatian: "Prijateljstvo",
    romanian: "Prietenie",
    spanish: "Amistad",
    bulgarian: "Приятелство",
    lithuanian: "Draugystė",
    italian: "Amicizia",
    french: "Amitié",
    greek: "Φιλία",
    turkish: "Dostluk",
  },
  {
    id: 2,
    category: "Film",
    english: "History",
    portuguese: "História",
    czech: "Historie",
    deutsch: "Geschichte",
    finnish: "Historia",
    croatian: "Povijest",
    romanian: "Istorie",
    spanish: "Historia",
    bulgarian: "История",
    lithuanian: "Istorija",
    italian: "Storia",
    french: "Histoire",
    greek: "Ιστορία",
    turkish: "Tarih",
  },
  {
    id: 3,
    category: "Film",
    english: "Revolt",
    portuguese: "Revolta",
    czech: "Vzpoura",
    deutsch: "Aufstand",
    finnish: "Kapina",
    croatian: "Otpor",
    romanian: "Revoltă",
    spanish: "Revuelta",
    bulgarian: "Бунт",
    lithuanian: "Maištas",
    italian: "Rivolta",
    french: "Révolte",
    greek: "Εξέγερση",
    turkish: "İsyan",
  },
  {
    id: 4,
    category: "Film",
    english: "Train",
    portuguese: "Comboio",
    czech: "Vlak",
    deutsch: "Zug",
    finnish: "Juna",
    croatian: "Vlak",
    romanian: "Tren",
    spanish: "Tren",
    bulgarian: "Влак",
    lithuanian: "Traukinys",
    italian: "Treno",
    french: "Train",
    greek: "Τρένο",
    turkish: "Takım",
  },
  {
    id: 5,
    category: "Film",
    english: "Family",
    portuguese: "Família",
    czech: "Rodina",
    deutsch: "Familie",
    finnish: "Perhe",
    croatian: "Obitelj",
    romanian: "Familie",
    spanish: "Familia",
    bulgarian: "Семейство",
    lithuanian: "Šeima",
    italian: "Famiglia",
    french: "Famille",
    greek: "Οικογένεια",
    turkish: "Aile",
  },
  {
    id: 6,
    category: "Film",
    english: "School",
    portuguese: "Escola",
    czech: "Škola",
    deutsch: "Schule",
    finnish: "Koulu",
    croatian: "Škola",
    romanian: "Școală",
    spanish: "Escuela",
    bulgarian: "Училище",
    lithuanian: "Mokykla",
    italian: "Scuola",
    french: "École",
    greek: "Σχολείο",
    turkish: "Okul",
  },
  {
    id: 7,
    category: "Film",
    english: "Nature",
    portuguese: "Natureza",
    czech: "Příroda",
    deutsch: "Natur",
    finnish: "Luonto",
    croatian: "Priroda",
    romanian: "Natură",
    spanish: "Naturaleza",
    bulgarian: "Природа",
    lithuanian: "Gamta",
    italian: "Natura",
    french: "Nature",
    greek: "Φύση",
    turkish: "Doğa",
  },
  {
    id: 8,
    category: "Film",
    english: "Work",
    portuguese: "Trabalho",
    czech: "Práce",
    deutsch: "Arbeit",
    finnish: "Työ",
    croatian: "Rad",
    romanian: "Muncă",
    spanish: "Trabajo",
    bulgarian: "Работа",
    lithuanian: "Darbas",
    italian: "Lavoro",
    french: "Travail",
    greek: "Εργασία",
    turkish: "İş",
  },
  {
    id: 9,
    category: "Film",
    english: "Imagination",
    portuguese: "Imaginação",
    czech: "Fantazie",
    deutsch: "Vorstellungskraft",
    finnish: "Kuvittelu",
    croatian: "Imaginacija",
    romanian: "Imaginație",
    spanish: "Imaginación",
    bulgarian: "Въображение",
    lithuanian: "Vaizduotė",
    italian: "Immaginazione",
    french: "Imagination",
    greek: "Φαντασία",
    turkish: "Hayal gücü",
  },
  {
    id: 10,
    category: "Film",
    english: "Equality",
    portuguese: "Igualdade",
    czech: "Rovnoprávnost",
    deutsch: "Gleichberechtigung",
    finnish: "Tasa-arvo",
    croatian: "Ravnopravnost",
    romanian: "Egalitate",
    spanish: "Igualdad",
    bulgarian: "Равенство",
    lithuanian: "Lygiateisiškumas",
    italian: "Uguaglianza",
    french: "Égalité",
    greek: "Ισότητα",
    turkish: "Eşitlik",
  },
  {
    id: 11,
    category: "About the Film",
    english: "Location",
    portuguese: "Localização",
    czech: "Lokalita",
    deutsch: "Ort",
    finnish: "Sijainti",
    croatian: "Lokacija",
    romanian: "Locație",
    spanish: "Ubicación",
    bulgarian: "Локация",
    lithuanian: "Vietovė",
    italian: "Posizione",
    french: "Emplacement",
    greek: "Τοποθεσία",
    turkish: "Konum",
  },
  {
    id: 12,
    category: "About the Film",
    english: "Making of",
    portuguese: "Making of",
    czech: "Natáčení",
    deutsch: "Hinter den Kulissen",
    finnish: "Kuvaukset",
    croatian: "Sa snimanja",
    romanian: "Cum s-a făcut",
    spanish: "El making of",
    bulgarian: "Зад кадър",
    lithuanian: "Kūrybinis procesas",
    italian: "Il making of",
    french: "Making of",
    greek: "Πώς γυρίστηκε",
    turkish: "Yapımı",
  },
  {
    id: 13,
    category: "About the Film",
    english: "Testimony",
    portuguese: "Testemunho",
    czech: "Svědectví",
    deutsch: "Erfahrungsbericht",
    finnish: "Todistus",
    croatian: "Izjave",
    romanian: "Mărturie",
    spanish: "Testimonio",
    bulgarian: "Свидетелство",
    lithuanian: "Liudijimai",
    italian: "Testimonianza",
    french: "Témoignage",
    greek: "Διακήρυξη",
    turkish: "Tanıklık",
  },
  {
    id: 14,
    category: "Experiences",
    english: "Workshop",
    portuguese: "Workshop",
    czech: "Workshop",
    deutsch: "Workshop",
    finnish: "Työpaja",
    croatian: "Radionice",
    romanian: "Workshop",
    spanish: "Workshop",
    bulgarian: "Уъркшоп",
    lithuanian: "Dirbtuvės",
    italian: "Workshop",
    french: "Workshop",
    greek: "Εργαστήριο",
    turkish: "Atölye",
  },
  {
    id: 15,
    category: "Experiences",
    english: "Training",
    portuguese: "Formação",
    czech: "Školení",
    deutsch: "Training",
    finnish: "Koulutus",
    croatian: "Obuke",
    romanian: "Antrenament",
    spanish: "Formación",
    bulgarian: "Обучение",
    lithuanian: "Mokymai",
    italian: "Formazione",
    french: "Formation",
    greek: "Προπόνηση",
    turkish: "Eğitim",
  },
  {
    id: 16,
    category: "Experiences",
    english: "Screening",
    portuguese: "Projeção",
    czech: "Projekce",
    deutsch: "Screening",
    finnish: "Esitys",
    croatian: "Projekcije",
    romanian: "Proiecție",
    spanish: "Proyección",
    bulgarian: "Прожекция",
    lithuanian: "Peržiūros",
    italian: "Proiezione",
    french: "Projection",
    greek: "Προβολή",
    turkish: "Gösterim",
  },
  {
    id: 16,
    category: "Experiences",
    english: "Student creations",
    portuguese: "Criações de estudantes",
    czech: "Tvorba studentů",
    deutsch: "Werke von Schüler:innen",
    finnish: "Opiskelijoiden luomukset",
    croatian: "Radovi učenika",
    romanian: "Creații ale studenților",
    spanish: "Creaciones de Estudiantes",
    bulgarian: "Творчество на ученици",
    lithuanian: "Mokinių kūryba",
    italian: "Creazioni degli studenti",
    french: "Créations étudiantes",
    greek: "Δημιουργίες φοιτητών",
    turkish: "Öğrenci kreasyonları",
  },
];
