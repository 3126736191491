import React from 'react';

import europeFlag from '../../assets/img/creative-europe.png'
import './Footer.css';

export default function Footer() {

  return (
    <div className="secondaryFooterContainer">
      <div className="secondaryFooter">
        <img
          className="footerEurope"
          src={europeFlag}
          alt="Europe Flag"
        />

        <div className="footerText">
          <p>
            This website has been produced with the financial support of the European Union under the grant agreement No 101059640.
          </p>
        </div>
      </div>
    </div>
  );
}

