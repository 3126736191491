import React, { useCallback, useEffect, useRef } from "react";
import Slider from "react-slick";

import ArrowBack from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowNext from "@mui/icons-material/ArrowForwardIosRounded";
import AddIcon from "@mui/icons-material/AddRounded";

import "./Slider.css";
import { isLoggedIn } from "../../utils/auth";

const DropzoneArea = ({
  fieldName,
  inputId,
  children,
  handleFormChange,
  filesAccepted,
  btnId,
  isInfinite,
  images,
}) => {
  const carouselSettings = {
    className: "mediaSlider",
    dots: true,
    infinite: isInfinite,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  const myCarousel = useRef(null);

  const next = useCallback((event) => {
    event.preventDefault();
    myCarousel.current.slickNext();
  });

  const previous = useCallback((event) => {
    event.preventDefault();
    myCarousel.current.slickPrev();
  });

  useEffect(() => {
    myCarousel.current.slickGoTo(images?.length - 2);
  }, [images]);

  console.log(children);

  return (
    <>
      <div className="formField sliderField">
        <div className="sliderHeader">
          <label htmlFor={inputId} className="sliderLabel">
            {fieldName}
          </label>
          <div
            className="mediaControllers"
            style={{
              display: "flex !important",
              alignItems: "center !important",
            }}
          >
            <label htmlFor={btnId} style={{ width: "140px" }}>
              <div style={{ paddingTop: "8px" }}>Upload Image</div>
            </label>
            <input
              key={(images && images.length) || ""}
              type="file"
              id={btnId}
              className="addMedia"
              onChange={handleFormChange}
              name={fieldName}
              accept={filesAccepted}
              multiple
            ></input>
          </div>
        </div>

        <Slider id={inputId} ref={myCarousel} {...carouselSettings}>
          {images && images.length ? (
            children
          ) : (
            <div>
              <p
                style={{
                  marginTop: "90px",
                  fontFamily: "Arial",
                  color: "#757575",
                  textAlign: "center",
                }}
              >
                Please upload one image to set as the popup background
              </p>
            </div>
          )}
        </Slider>
      </div>
    </>
  );
};

export default DropzoneArea;
