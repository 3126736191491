import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import ReactLoading from "react-loading";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import Table from "../../components/table/Table";
import Loading from "../../components/loading/Loading";
import usePrevious from "../../utils/usePrevious";
import { ITEMS_PER_PAGE, getParams } from "../../utils/params";
import { isLoggedIn } from "../../utils/auth";

import CountryIcon from "@mui/icons-material/LanguageOutlined";
import UserRole from "@mui/icons-material/AccountBox";
import SearchIcon from "@mui/icons-material/TravelExploreOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

import settings from "../../api/config";
import "./Home.css";

export default function App() {
  const [movieLocations, setMovieLocations] = useState();
  const [userLogged, setUserLogged] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [pageCount, setPagesCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [debouncedSearchInput] = useDebounce(searchInput, 1000);
  const previousPage = usePrevious(selectedPage);

  const navigate = useNavigate();
  const loadingClass = tableLoading ? "tableContainer pageLoading" : "tableContainer";

  useEffect(() => {
    getMovieLocations(selectedPage, debouncedSearchInput);

    if (isLoggedIn()) {
      const token = JSON.parse(localStorage.getItem("accessToken"));

      axios({
        method: "get",
        url: `${settings.BASE_URL}/users/me`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          setUserLogged(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("An error occurred:", error.response);

          // Handle token expiration
          localStorage.removeItem("accessToken");
          window.location.reload();
        });
    }
  }, []);

  useEffect(() => {
    let page = selectedPage;

    // Reset page in case of search
    if (previousPage === selectedPage) {
      setSelectedPage(0);
      page = 0;
    }

    getMovieLocations(page, debouncedSearchInput);
  }, [debouncedSearchInput, selectedPage]);

  // Get updated movie locations from API
  const getMovieLocations = (selectedPage, searchInput) => {
    const { search, sort, start, limit } = getParams(selectedPage, searchInput);
    const searchParams = search !== "" ? `${search}&${sort}` : `${sort}`;

    setTableLoading(true);

    axios
      .get(`${settings.BASE_URL}/movie-locations?${searchParams}&_start=${start}&_limit=${limit}`)
      .then((response) => {
        const res = response.data;
        setMovieLocations(res);
        setTableLoading(false);
      });

    axios
      .get(`${settings.BASE_URL}/movie-locations/count?${searchParams}&_start=${start}&_limit=${limit}`)
      .then((response) => {
        const res = response.data;
        setPagesCount(Math.ceil(res / ITEMS_PER_PAGE));
      });
  };

  // Get input value from search input
  const handleInput = (event) => {
    event.preventDefault();

    const inputFilter = event.target.value.toLowerCase();
    setSearchInput(inputFilter);
    setTableLoading(true);
  };

  // Refresh movie locations after pin deletion
  const refreshMovieLocations = () => {
    getMovieLocations(selectedPage, debouncedSearchInput);
  };

  // Change selected page
  const changePage = ({ selected }) => {
    setSelectedPage(selected);
  };

  // Remove token from local storage and redirect user to login form
  const logout = () => {
    localStorage.removeItem("accessToken");
    navigate("/");
  };

  return (
    <>
      <div className="tablePageHeader">
        <div className="blueBg"></div>
        <div className="waveBg"></div>
        <Container maxWidth="xl">
          {userLogged ? (
            <div className="headerContent">
              <div className="leftSide">
                <h1>Welcome {userLogged?.firstname}</h1>
                <div className="userInfo">
                  <div>
                    <CountryIcon></CountryIcon>
                    <span>{userLogged?.lastname}</span>
                  </div>
                  <div>
                    <UserRole /> <span>{userLogged?.roles[0].name}</span>
                  </div>
                </div>
              </div>
              <div className="rightSide">
                <button onClick={() => logout()}>
                  <LogoutIcon />
                  Logout
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Container>
      </div>
      <div className="tableSection">
        {loading ? (
          <Loading />
        ) : (
          <Container maxWidth="xl">
            <div className="tableFilters">
              <TextField
                id="input-with-icon-textfield"
                className="searchTextField"
                placeholder="Search for a movie location"
                onChange={handleInput}
                name="locationSearch"
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <button
                onClick={() => {
                  navigate("/add-location");
                }}
              >
                Add Location
              </button>
            </div>

            <div className={loadingClass}>
              <Table
                tableItems={movieLocations}
                filter={searchInput}
                setLoading={setTableLoading}
                refreshMovieLocations={refreshMovieLocations}
              />

              {tableLoading ? (
                <div className="tableLoadingContainer">
                  <ReactLoading type="spin" color="#08798E" />
                </div>
              ) : (
                <></>
              )}
            </div>

            {movieLocations && movieLocations.length > 0 ? (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                forcePage={selectedPage}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"controllersBtn"}
                nextLinkClassName={"controllersBtn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"activePagination"}
              />
            ) : (
              <></>
            )}
          </Container>
        )}
      </div>
    </>
  );
}
