export const ITEMS_PER_PAGE = 6;

export const getParams = (page, searchQuery) => {
  const sort = "_sort=createdAt:DESC";
  const search = searchQuery ? `_q=${searchQuery}` : "";
  const start = page * ITEMS_PER_PAGE;
  const limit = ITEMS_PER_PAGE;

  const res = { sort, search, start, limit };
  return res;
};
