import React, { useCallback, useEffect, useRef } from "react";
import Slider from "react-slick";

import ArrowBack from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowNext from "@mui/icons-material/ArrowForwardIosRounded";
import AddIcon from "@mui/icons-material/AddRounded";

import "./Slider.css";
import { isLoggedIn } from "../../utils/auth";

const SliderComponent = ({
  fieldName,
  inputId,
  children,
  handleFormChange,
  filesAccepted,
  btnId,
  isInfinite,
  images,
  videos,
}) => {
  const carouselSettings = {
    className: "mediaSlider",
    dots: true,
    infinite: isInfinite,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  const myCarousel = useRef(null); // para que serve?

  const next = useCallback((event) => {
    event.preventDefault();
    myCarousel.current.slickNext();
  });

  const previous = useCallback((event) => {
    event.preventDefault();
    myCarousel.current.slickPrev();
  });

  useEffect(() => {
    myCarousel.current.slickGoTo(images?.length - 2);
  }, [images]);

  useEffect(() => {
    myCarousel.current.slickGoTo(videos?.length - 2);
  }, [videos]);

  return (
    <>
      <div className="formField sliderField">
        <div className="sliderHeader">
          <label htmlFor={inputId} className="sliderLabel">
            {fieldName}
          </label>
          <div className="mediaControllers">
            <label htmlFor={btnId}>
              <AddIcon />
            </label>
            <input
              key={(images && images.length) || (videos && videos.length) || ""}
              type="file"
              id={btnId}
              className="addMedia"
              onChange={handleFormChange}
              name={fieldName}
              accept={filesAccepted}
              multiple
            ></input>

            <button onClick={previous} className="sliderArrow">
              <ArrowBack />
            </button>

            <button onClick={next} className="sliderArrow">
              <ArrowNext />
            </button>
          </div>
        </div>

        <Slider id={inputId} ref={myCarousel} {...carouselSettings}>
          {/* {children} */}
          {images && images.length || videos && videos.length ? (
            children
          ) : (
            <div>
              <p
                style={{
                  marginTop: "95px",
                  fontFamily: "Arial",
                  color: "#757575",
                  textAlign: "center",
                }}
              >
                {filesAccepted.includes("image") ? "Please upload at least one image to add to image gallery" : "Please upload videos to add to video gallery"}
              </p>
            </div>
          )}
        </Slider>
      </div>
    </>
  );
};

export default SliderComponent;
