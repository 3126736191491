import React, { useState } from "react";

import Modal from "@mui/material/Modal";

import AlertIcon from "@mui/icons-material/ErrorOutline";

import "./modal.css";

const ModalComponent = ({
  isOpen,
  handleModalClose,
  modalIcon,
  modalMessage,
  action,
  objectClicked,
}) => {
  const checkMessage = () => {
    const hasBracket = modalMessage.includes("(" || ")");

    if (hasBracket) {
      const splittedMsg = modalMessage.split(/[()]/);

      return (
        <div className="splittedMsg">
          <p>{splittedMsg[0]}</p>
          <p>{splittedMsg[1]}</p>
        </div>
      );
    } else {
      return <div className="message">{modalMessage}</div>;
    }
  };

  const checkElement = checkMessage();

  const checkIcon = () => {
    switch (modalIcon) {
      case "alert":
        return <AlertIcon className="alertIcon" />;
        break;

      default:
        break;
    }
  };

  const icon = checkIcon();

  return (
    <>
      <Modal open={isOpen} onClose={handleModalClose}>
        <div className="modal">
          <div className="modalContent">
            {icon}
            {checkElement}
            <div className="modalBtns">
              <button
                className="confirmBtn"
                onClick={() => action(objectClicked)}
              >
                Confirm
              </button>
              <button className="cancelBtn" onClick={() => handleModalClose()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalComponent;
