import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";

import FormField from "../../components/field/Field";
import Modal from "../modal/modal";
import { tagList } from "./TagsTranslation";

import DocumentIcon from "@mui/icons-material/InsertDriveFileRounded";
import AddIcon from "@mui/icons-material/AddRounded";

import "./AddTab.css";

const TranslationTab = ({ setTranslationsSub, movieLocation, englishTitle, englishDescription }) => {
  const [translations, setTranslations] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [tags, setTags] = useState([]);
  const [maxTags, setMaxTags] = useState(5);
  const [modalStatus, setModalStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [itemClicked, setItemClicked] = useState();

  const [isDisabled, setIsDisabled] = useState(false);

  const [formData, setFormData] = useState();
  const [documents, setDocuments] = useState([]);

  const [tagRemoved, setTagRemoved] = useState("");

  const languages = [
    "english",
    "portuguese",
    "czech",
    "deutsch",
    "finnish",
    "croatian",
    "romanian",
    "spanish",
    "bulgarian",
    "lithuanian",
    "italian",
    "french",
    "turkish",
    "greek",
  ];

  //sort languages in ascending order
  languages.sort((a, b) => (a > b ? 1 : -1));

  //English is first by default
  languages.map((language, index) => {
    if (language === "english") {
      languages.splice(index, 1);
      languages.unshift(language);
    }
  });

  const tagListFiltered = tagList.filter((op) => op.category === movieLocation?.type);

  useEffect(() => {
    const translationsArray = [];

    languages.map((language) => {
      translationsArray.push({
        language: language,
        __component: `documents-tags.${language}`,
        Documents: [],
        title: movieLocation?.AtlasTranslations[language]?.title || "",
        description: movieLocation?.AtlasTranslations[language]?.description || "",
        Tags: [],
      });
    });

    setTranslations(translationsArray);
  }, []);

  let domTags = document.getElementsByClassName("react-tag-input__tag__remove");

  const domTagClicked = (event, domTag) => {
    event.preventDefault();

    let parent = domTag.parentNode;
    setTagRemoved(parent.firstChild.innerHTML);
  };

  for (let i = 0; i < domTags.length; i++) {
    domTags[i].addEventListener(
      "click",
      (event) => {
        domTagClicked(event, domTags[i]);
      },
      false
    );
  }

  useEffect(() => {
    if (!movieLocation) {
      return;
    }

    let copyTranslations = translations.map((object) => ({ ...object }));

    for (let i = 0; i < movieLocation?.AtlasTranslations.length; i++) {
      copyTranslations.map((translation) => {
        if (translation.__component === movieLocation.AtlasTranslations[i].__component) {
          translation.Documents = movieLocation.AtlasTranslations[i].Documents;
          translation.Tags = movieLocation.AtlasTranslations[i].Tags;

          translation.Tags = translation?.Tags.map((tag) => tag.TagName);

          translation.title = movieLocation.AtlasTranslations[i].title;
          translation.description = movieLocation.AtlasTranslations[i].description;
        }
      });
    }

    setTranslations(copyTranslations);

    setDocuments(copyTranslations[0].Documents);
    setTags(copyTranslations[0].Tags);
    console.log("ML TAGS: ", movieLocation);
  }, [movieLocation]);

  const handleTabChange = (event, newValue) => {
    setTags(translations[newValue].Tags);

    setTabValue(newValue);
  };

  const handleTags = (newTags) => {
    if (newTags.length <= 5) {
      let tagsCopy = [...tags];
      if (tagsCopy.length > newTags.length && tabValue === 0) {
        let englishIndex = tagsCopy.indexOf(tagRemoved);
        for (let i = 1; i < translations.length; i++) {
          if (translations[i].Tags.length >= englishIndex + 1) {
            translations[i].Tags.splice(englishIndex, 1);
          }
        }
      }
      setTags(newTags);
      translateTags(newTags);
    }
  };

  const translateTags = (newTags) => {
    if (tabValue === 0) {
      for (let i = 1; i < translations.length; i++) {
        const translatedTags = newTags.map((tag) => {
          const translatedTag = tagList.find((entry) => entry.english === tag);
          return translatedTag ? translatedTag[translations[i].language] : tag;
        });
        translations[i].Tags = translatedTags;
      }
    }
  };

  const changeMaxTags = () => {
    if (tabValue === 0) {
      setMaxTags(5);
    } else {
      setMaxTags(translations[0]?.Tags?.length);
    }
  };

  useEffect(() => {
    if (translations[tabValue] !== undefined) {
      translations[tabValue].Tags = tags;
    }

    if (translations[0]?.Tags.length === 0) {
      translations.map((translation) => {
        translation.Tags = [];
      });
    }
    changeMaxTags();
    setTranslationsSub(translations);
  }, [tags]);

  useEffect(() => {
    if (tabValue !== 0 && translations[0]?.Tags?.length === 0) {
      setIsDisabled(true);
    } else setIsDisabled(false);

    setDocuments(translations[tabValue]?.Documents);
  }, [tabValue]);

  useEffect(() => {
    setTranslationsSub(translations);
  }, [documents]);

  useEffect(() => {
    setTranslationsSub(translations);
  }, [translations]);

  const handleFormChange = (event) => {
    event.preventDefault();

    let value = event.target.files;
    let name = event.target.getAttribute("name");

    const newFormData = { ...formData };
    newFormData[name] = value;

    setFormData(newFormData);

    const selectedDocs = Array.from(newFormData.documents);

    selectedDocs.map((doc) => {
      translations[tabValue].Documents.push(doc);
    });

    setDocuments(translations[tabValue].Documents);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTranslations((prevTranslations) =>
      prevTranslations.map((translation, index) => {
        if (index === tabValue) {
          return {
            ...translation,
            [name]: value,
          };
        }
        return translation;
      })
    );
  };

  const handleRemoveDoc = (doc) => {
    setDocuments(documents.filter((e) => e !== doc));
    translations[tabValue].Documents = translations[tabValue].Documents.filter((e) => e !== doc);
    setModalStatus(false);
  };

  const getFileExt = (file, request) => {
    let fileName = file.name;

    let name = fileName.split(".")[0];
    let extension = fileName.split(".")[1];

    if (request === "extension") {
      return <label>{extension}</label>;
    } else if (request === "name") {
      return <label>{name}</label>;
    }
  };

  const modalController = (document) => {
    if (modalStatus === false) {
      setModalStatus(true);
      setMessage(`Do you really want to delete this document? This action cannot be undone!`);
      setItemClicked(document);
    } else {
      setModalStatus(false);
    }
  };

  return (
    <>
      <div className="translationSection">
        <label className="inputLabel">Translations</label>

        <TabContext value={tabValue}>
          <TabList
            variant="scrollable"
            scrollButtons
            className="tabList"
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: "#00868e" } }}
          >
            {translations.map((translation, index) => {
              return translation.language === "english" ? (
                <Tab key={index} label={translation.language} className="translationTab">
                  {translation.language}
                </Tab>
              ) : (
                <Tab
                  key={index}
                  label={translation.language}
                  className="translationTab"
                  // disabled={isDisabled}
                >
                  {translation.language}
                </Tab>
              );
            })}
          </TabList>

          <TabPanel className="tabItem" value={tabValue}>
            {movieLocation?.type !== "Film" ? (
              tabValue === 0 ? (
                <Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <FormField inputId={"titleInput"} fieldName={"title"}>
                      <input
                        type="text"
                        id="titleInput"
                        name="title"
                        value={englishTitle}
                        className="formInputDisabled"
                        disabled
                      ></input>
                    </FormField>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormField inputId={"descriptionInput"} fieldName={"description"}>
                      <textarea
                        type="text"
                        id="descriptionInput"
                        name="description"
                        value={englishDescription}
                        className="formDescriptionDisabled"
                        disabled
                      ></textarea>
                    </FormField>
                  </Grid>
                  <div className="tagsField">
                    <p>* Please insert the translations for Title and Description into respective language tab</p>
                  </div>
                </Grid>
              ) : (
                <Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <FormField inputId={"titleTransl"} fieldName={"title"}>
                      <input
                        type="text"
                        id="titleTransl"
                        name="title"
                        value={translations[tabValue].title}
                        onChange={handleChange}
                        className="formInput"
                        placeholder="Insert the title"
                      ></input>
                    </FormField>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormField inputId={"descriptionTransl"} fieldName={"description"}>
                      <textarea
                        type="text"
                        id="descriptionTransl"
                        name="description"
                        value={translations[tabValue].description}
                        onChange={handleChange}
                        className="formDescription"
                        placeholder="Insert the description"
                      ></textarea>
                    </FormField>
                  </Grid>
                  <div className="tagsField">
                    <p>* Please insert here the translations for Title and Description</p>
                  </div>
                </Grid>
              )
            ) : (
              <></>
            )}
            <div className="tagsField">
              <label className="inputLabel">Tags</label>
              {tabValue !== 0 && translations[0].Tags.length > 0 ? (
                <div className="engTags">
                  {translations[0].Tags.map((tag) => (
                    <label>{tag}</label>
                  ))}
                </div>
              ) : (
                <></>
              )}
              <Autocomplete
                multiple
                id="tags-standard"
                className="tags"
                options={tagListFiltered.map((tag) => tag.english)}
                getOptionLabel={(option) => option}
                value={tags}
                onChange={(_, newTags) => handleTags(newTags)}
                disabled={tabValue !== 0}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder={
                      movieLocation?.type !== "Film"
                        ? tags?.length < tagListFiltered?.length
                          ? "Type a tag"
                          : ""
                        : tags?.length < 5
                        ? "Type a tag"
                        : ""
                    }
                  />
                )}
              />

              {tabValue === 0 ? (
                <p>
                  * You need to insert at least one english tag to autofill tags in other languages (maximum of 5 tags)
                </p>
              ) : (
                <p>* This field is autofilled, to change the tags go to the English tab</p>
              )}
            </div>

            <div className="documentsSection">
              <div className="documentsHeader">
                <label className="sliderLabel">Documents</label>
                <div className="documentsController">
                  <label htmlFor={"documentsInput"}>
                    <AddIcon />
                  </label>
                  <input
                    type="file"
                    id="documentsInput"
                    name="documents"
                    onChange={handleFormChange}
                    accept="application/pdf"
                    multiple
                  />
                </div>
              </div>
              <Grid container spacing={2} className="docsGrid">
                {documents?.length > 0 &&
                  documents.map((doc) => (
                    <Grid item lg={3}>
                      <div className="documentItem">
                        <div className="documentIcon">
                          <DocumentIcon />
                          <div className="fileExtension">{getFileExt(doc, "extension")}</div>
                        </div>
                        <div className="fileName">{getFileExt(doc, "name")}</div>

                        <div
                          className="docRemove"
                          onClick={() => modalController(doc)}

                          // onClick={() => handleRemoveDoc(doc)}
                        >
                          <AddIcon></AddIcon>
                        </div>
                      </div>
                    </Grid>
                  ))}
              </Grid>
              <p className="tagsMsg">* Please insert documents into the respective language tab</p>
            </div>
          </TabPanel>
        </TabContext>
      </div>

      {modalStatus && (
        <Modal
          isOpen={modalStatus}
          handleModalClose={modalController}
          modalMessage={message}
          action={handleRemoveDoc}
          modalIcon={"alert"}
          objectClicked={itemClicked}
        />
      )}
    </>
  );
};

export default TranslationTab;
