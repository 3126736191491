import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import LocationForm from "./pages/add-location/AddLocation";
import HomePage from "./pages/home/Home";
import UpdatePage from "./pages/update-location/UpdateLocation";

import PrivateRoute from "./PrivateRoute";
import Footer from "./components/footer/Footer";

const BASE_PATH = window.BASE_PATH ? window.BASE_PATH : "";

export default function App() {
  return (
    <>
      <BrowserRouter basename={"/" + BASE_PATH}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/movie-locations"
            element={
              <PrivateRoute>
                <HomePage />
                <Footer />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-location"
            element={
              <PrivateRoute>
                <LocationForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/update"
            element={
              <PrivateRoute>
                <UpdatePage />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
