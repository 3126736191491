import React, { useRef, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import { GoogleMap, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import BackArrow from "@mui/icons-material/ArrowBackIosNewRounded";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import "./AddLocation.css";
import FormField from "../../components/field/Field";
import Loading from "../../components/loading/Loading";
import Slider from "../../components/slider/Slider";
import TranslationTab from "../../components/tabs/AddTab";

import AddIcon from "@mui/icons-material/AddRounded";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";

import { isLoggedIn } from "../../utils/auth";

import { Switch, FormControlLabel } from "@mui/material";

import settings from "../../api/config";

export default function App() {
  const mapRef = useRef(null);

  const navigate = useNavigate();
  const [movies, setMovies] = useState([]);

  const [selectedMovie, setSelectedMovie] = useState();
  const [formData, setFormData] = useState();
  const [images, setImages] = useState([]);
  //const [backgroundImage, setBackgroundImage] = useState([]);
  const [videos, setVideos] = useState([]);
  const [audio, setAudio] = useState([]);
  const [links, setLinks] = useState([]);

  const [uploadedImages, setUploadedImages] = useState([]);
  //const [uploadedBackgroundImage, setUploadedBackgroundImage] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);

  const [translations, setTranslations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sliders, setSliders] = useState(0);
  const [isInfinite, setIsInfinite] = useState();

  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const [publication, setPublication] = useState(false);

  const coordinatesInput = document.getElementById("coordinatesInput");

  const [token, setToken] = useState();
  //Google maps variables
  const libraries = ["places"];
  const [zoom, setZoom] = useState(4);
  const [center, setCenter] = useState({
    lat: 41.1579438,
    lng: -8.6291053,
  });

  const types = ["Film", "About the Film", "Experiences"];

  //Select options properties
  const SelectProps = {
    PaperProps: {
      style: {
        maxHeight: 40 * 4.5 + 8,
        width: 250,
      },
    },
  };

  //API call to get movies list
  useEffect(() => {
    if (isLoggedIn()) {
      setToken(JSON.parse(localStorage.getItem("accessToken")));
    }

    axios
      .get(`${settings.BASE_URL}/movies/all`)
      .then((response) => {
        let sorted = response.data.sort((a, b) => {
          return (a.OriginalTitle > b.OriginalTitle) - (a.OriginalTitle < b.OriginalTitle);
        });

        setLoading(false);
        setMovies(sorted);
      })
      .catch((error) => {
        setLoading(false);

        // Handle error.
        console.log("An error occurred:", error.response);
      });
  }, []);

  const mediaDisplay = (extensions, setUploaded, media, setMedia) => {
    let i = 0;
    const selectedMediaArray = Array.from(media);

    while (i < selectedMediaArray.length) {
      const fileExtension = selectedMediaArray[i].type.substring(selectedMediaArray[i].type.indexOf("/") + 1);

      if (!extensions.includes(fileExtension)) {
        selectedMediaArray.splice(i, 1);
      } else {
        i++;
      }
    }

    const mediaArray = selectedMediaArray.map((media) => {
      return URL.createObjectURL(media);
    });

    let uploadContent = [];
    for (let i = 0; i < media.length; i++) {
      uploadContent.push(media.item(i));
    }

    setUploaded((prevMedia) => prevMedia?.concat(uploadContent));

    setMedia((prevMedia) => prevMedia?.concat(mediaArray));
  };

  //console.log("BACKGROUND IMAGE VAL", backgroundImage);
  //console.log("GALLERY IMAGE VAL", images);

  useEffect(() => {
    //console.log("UPLOADED IMAGES: ", uploadedImages);
  }, [uploadedImages]);

  const handleFormChange = (event) => {
    event.preventDefault();

    let name = "";
    let value = "";

    if (event.target.files === undefined) {
      name = event.target.name;
      value = event.target.value;
      setSelectedMovie(value);
    } else {
      name = event.target.getAttribute("name");
      value = event.target.files;
    }

    if (event.target.name === "title") {
      value = event.target.value;
    }

    const newFormData = { ...formData };
    newFormData[name] = value;

    setFormData(newFormData);

    if (event.target.name === "images") {
      let allowedExtensions = ["jpeg", "png", "gif", "svg", "tiff", "ico", "dvu"];

      mediaDisplay(allowedExtensions, setUploadedImages, newFormData.images, setImages);
    } else if (event.target.name === "videos") {
      let allowedExtensions = ["mpeg", "mp4", "quicktime", "wmv", "avi", "flu"];

      if (newFormData?.videos[0].size >= 200000000) {
        toast.error("Insert a video less than 200MB", {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }

      mediaDisplay(allowedExtensions, setUploadedVideos, newFormData.videos, setVideos);
    } else if (event.target.name === "audio") {
      const selectedDocs = Array.from(newFormData.audio);
      if (audio) {
        setAudio((prevDocs) => prevDocs?.concat(selectedDocs));
      } else {
        setAudio(selectedDocs);
      }
    }
  };

  const [imgSlider, setImgSlider] = useState(false);
  const [vidSlider, setVidSlider] = useState(false);

  const changeSlider = (media, inf) => {
    if (media?.length > 1) {
      inf(true);
    } else {
      inf(false);
    }
  };

  useEffect(() => {
    changeSlider(images, setImgSlider);
  }, [images]);

  useEffect(() => {
    changeSlider(videos, setVidSlider);
  }, [videos]);

  //Location Input Auto Complete
  const PlacesAutoComplete = () => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        types: ["(cities)"],
      },
    });

    const ref = useOnclickOutside(() => {
      // When user clicks outside of the component, dismiss suggestions
      clearSuggestions();
    });

    const handleInput = (e) => {
      // Update the keyword of the input element
      setValue(e.target.value);
    };

    const handleSelect =
      ({ description }) =>
      () => {
        setValue(description, false);
        // Get latitude and longitude via utility functions
        getGeocode({ address: description }).then((results) => {
          try {
            const addressComponents = results[0].address_components;

            //console.log("ADDRESS: ", addressComponents);
            let cityName = "";
            if (addressComponents?.length > 1) {
              addressComponents.map((component) => {
                if (component?.types.includes("locality")) {
                  cityName = component.long_name;
                  setCity(component.long_name);
                } else {
                  if (component?.types.includes("administrative_area_level_2") && cityName?.length === 0) {
                    setCity(component.long_name);
                  }
                }

                if (component?.types.includes("country")) {
                  setCountry(component.long_name);
                }
              });
            } else {
              setCountry(addressComponents[0].long_name);
              setCity("");
            }

            const { lat, lng } = getLatLng(results[0]);
            coordinatesInput.value = `${lat.toFixed(4)}, ${lng.toFixed(4)}`;

            setLatitude(lat.toFixed(4));
            setLongitude(lng.toFixed(4));

            setCenter({
              lat: lat,
              lng: lng,
            });

            setZoom(9);
          } catch (error) {
            console.log("Error: ", error);
          }
        });
      };

    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <li key={place_id} onClick={handleSelect(suggestion)} className="suggestionItem">
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      });

    return (
      <div ref={ref}>
        <input
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={country?.length !== 0 ? (city.length !== 0 ? `${city}, ${country}` : `${country}`) : "Location"}
          className="formInput locationInput"
        />
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        {status === "OK" && <ul className="suggestionList">{renderSuggestions()}</ul>}
      </div>
    );
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!formData?.movie) {
      toast.error("Select a Movie!", {
        position: "top-left",
        autoClose: 3000,
        theme: "colored",
      });

      return;
    }

    if (!formData.type) {
      toast.error("Select Type!", {
        position: "top-left",
        autoClose: 3000,
        theme: "colored",
      });

      return;
    }

    if (formData.type !== "Film") {
      if (!formData.title) {
        toast.error("Insert a Title!", {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }

      if (!formData.description) {
        toast.error("Insert a description!", {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });

        return;
      }

      if (uploadedImages.length === 0) {
        toast.error("Select at least one image!", {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });

        return;
      }

      if (links?.length !== 0) {
        const invalidFields = links.map((field, index) => {
          if (field.urlDescription === "" && field.url === "") {
            toast.error(
              "Fill the URL description and URL fields or remove the white space fields if you don't want to fill them!",
              {
                position: "top-left",
                autoClose: 3000,
                theme: "colored",
              }
            );
            return;
          }
        });
      }
    }

    //console.log("ENTRA AQUI");

    let locationId = "";
    const locationObj = {
      Country: country,
      City: city,
      lat: latitude,
      long: longitude,
    };

    const filteredTranslations = translations.filter(
      (translation) => translation.Tags.length !== 0 || translation.Documents.length !== 0
    );

    setLoading(true);

    //Post location api call
    try {
      let response = await axios.post(`${settings.BASE_URL}/locations`, locationObj, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      locationId = response.data.id;
    } catch (error) {
      setLoading(false);

      // Handle error.
      toast.error("Enter a location!", {
        position: "top-left",
        autoClose: 3000,
        theme: "colored",
      });

      return;
    }

    let allUploads = [];

    for (let i = 0; i < filteredTranslations.length; i++) {
      filteredTranslations[i].Tags = filteredTranslations[i].Tags.map((tag) => {
        return { TagName: tag };
      });

      const data = new FormData();

      if (filteredTranslations[i].Documents.length === 0) {
        continue;
      }

      for (let j = 0; j < filteredTranslations[i].Documents.length; j++) {
        if (filteredTranslations[i].Documents.length !== 0) {
          data.append("files", filteredTranslations[i].Documents[j]);
        }
      }

      let uploadDocuments = await axios.post(`${settings.BASE_URL}/upload/`, data);

      filteredTranslations[i].Documents = uploadDocuments.data;

      uploadDocuments.data.map((upload) => allUploads.push(upload));
    }

    let movieLocation = {
      Images: [],
      Videos: [],
      Audio: [],
      AtlasTranslations: filteredTranslations,
      movie: formData?.movie?.id,
      location: locationId,
      title: formData?.type === "Film" ? "--" : formData?.title,
      description: formData?.type === "Film" ? "--" : formData?.description,
      type: formData?.type,
      Links: links,
      publish: publication,
    };

    console.log("mov loc submission", movieLocation);

    if (uploadedImages.length > 0) {
      const data = new FormData();

      uploadedImages.map((image) => {
        data.append("files", image);
      });

      let uploadImages = await axios.post(`${settings.BASE_URL}/upload/`, data);

      movieLocation.Images = uploadImages.data;

      uploadImages.data.map((upload) => allUploads.push(upload));
    }

    /*AUDIO upload*/
    if (audio.length > 0) {
      const data = new FormData();

      //console.log("entra aqui!!", audio);

      audio.map((audio) => {
        data.append("files", audio);
      });

      let uploadAudio = await axios.post(`${settings.BASE_URL}/upload/`, data);

      movieLocation.Audio = uploadAudio.data;

      uploadAudio.data.map((upload) => allUploads.push(upload));

      //console.log("UPLOADS !!!!!", allUploads);
    }
    //----------------

    if (uploadedVideos.length > 0) {
      const data = new FormData();

      for (let videoIndex = 0; videoIndex < uploadedVideos.length; videoIndex++) {
        data.append("files", uploadedVideos[videoIndex]);
      }

      let uploadVideos = await axios.post(`${settings.BASE_URL}/upload/`, data);

      movieLocation.Videos = uploadVideos.data;

      uploadVideos.data.map((upload) => allUploads.push(upload));
    }

    //console.log("INFO ENVIADA", movieLocation);

    await axios
      .post(`${settings.BASE_URL}/movie-locations`, movieLocation, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);

        navigate("/movie-locations");
      })
      .catch((error) => {
        setLoading(false);

        // Handle error.
        toast.error(error.response.data.message, {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });

        allUploads.map((upload) => {
          axios.delete(`${settings.BASE_URL}/upload/files/${upload.id}`, {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
        });

        console.log("An error occurred:", error.response);
      });
  };

  const handleRemoveDoc = (doc) => {
    //console.log("ARRAY: ", formData);
    const formDataCopy = formData;
    //console.log("FORM DATA: ", formDataCopy);

    setAudio(audio.filter((e) => e !== doc));
  };

  const getFileExt = (file, request) => {
    let fileName = file.name;

    let name = fileName.split(".")[0];
    let extension = fileName.split(".")[1];

    if (request === "extension") {
      return <label>{extension}</label>;
    } else if (request === "name") {
      return <label>{name}</label>;
    }
  };

  const addLinkField = () => {
    setLinks([...links, { urlDescription: "", url: "" }]);
  };
  const removeLinkField = (index) => {
    const updatedLinks = [...links];
    updatedLinks.splice(index, 1);
    setLinks(updatedLinks);
  };

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedUploadedImages = [...uploadedImages];
    updatedUploadedImages.splice(index, 1);
    setUploadedImages(updatedUploadedImages);
  };

  const removeVideo = (index) => {
    const updatedVideos = [...videos];
    updatedVideos.splice(index, 1);
    setVideos(updatedVideos);

    const updatedUploadedVideos = [...uploadedVideos];
    updatedUploadedVideos.splice(index, 1);
    setUploadedVideos(updatedUploadedVideos);
  };

  const handleToggleChange = () => {
    setPublication(!publication);
  };

  console.log("IMAGES", images);
  console.log("UPLOADED IMAGES", uploadedImages);
  console.log("transl value 1st", translations);

  console.log("val publication", publication);

  return (
    <>
      <Grid className="mainGrid" container>
        <Grid item lg={7} md={12}>
          <div className="header">
            <Container maxWidth="md">
              <span
                className="backAnchor"
                onClick={() => {
                  navigate("/movie-locations");
                }}
              >
                <BackArrow></BackArrow> <label>Go Back</label>
              </span>
              <h1>Add Location</h1>
            </Container>
          </div>
          <form>
            {loading ? (
              <Loading />
            ) : (
              <Container maxWidth="md">
                <Grid container className="formGrid">
                  <Grid item lg={6} md={6} sm={6}>
                    <FormField inputId={"typeInput"} fieldName={"type"}>
                      <Select
                        id="typeInput"
                        className="movieInput"
                        onChange={handleFormChange}
                        defaultValue=""
                        name="type"
                        displayEmpty
                        //MenuProps={SelectProps}
                        inputProps={{ "aria-label": "Without label" }}
                        required
                      >
                        <MenuItem disabled value="" className="selectItem">
                          Type
                        </MenuItem>
                        {types.length > 0 &&
                          types.map((type) => (
                            <MenuItem key={type} value={type} className="selectItem">
                              {type}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormField>
                  </Grid>

                  <Grid lg={6} md={6} sm={6}>
                    <FormField inputId={"publishInput"} fieldName={"publish"}>
                      <FormControlLabel
                        control={<Switch checked={publication} onChange={handleToggleChange} color="primary" />}
                        label={publication === false ? "No" : "Yes"}
                        className="formControlLabel"
                      />
                    </FormField>
                  </Grid>

                  {formData?.type !== "Film" ? (
                    <Grid item lg={6} md={6} sm={6}>
                      <FormField inputId={"titleInput"} fieldName={"title"}>
                        <input
                          type="text"
                          id="titleInput"
                          name="title"
                          onChange={handleFormChange}
                          className="formInput"
                          placeholder="Insert the title"
                        ></input>
                      </FormField>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item lg={6} md={6} sm={6}>
                    <FormField inputId={"movieInput"} fieldName={"movie"}>
                      <Select
                        id="movieInput"
                        className="movieInput"
                        onChange={handleFormChange}
                        defaultValue=""
                        name="movie"
                        displayEmpty
                        MenuProps={SelectProps}
                        inputProps={{ "aria-label": "Without label" }}
                        required
                      >
                        <MenuItem disabled value="" className="selectItem">
                          Movie
                        </MenuItem>
                        {movies.length > 0 &&
                          movies.map((movie) => (
                            <MenuItem key={movie.id} value={movie} className="selectItem">
                              {movie.OriginalTitle}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormField>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6}>
                    <FormField inputId={"locationInput"} fieldName={"location"}>
                      <PlacesAutoComplete id="locationInput" />
                    </FormField>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6}>
                    <FormField inputId={"coordinatesInput"} fieldName={"coordinates"}>
                      <input type="text" id="coordinatesInput" placeholder="Coordinates" disabled></input>
                    </FormField>
                  </Grid>

                  {formData?.type !== "Film" ? (
                    <Grid container className="formGrid">
                      <Grid item lg={12} md={12}>
                        <FormField inputId={"descriptionInput"} fieldName={"description"}>
                          <textarea
                            type="text"
                            id="descriptionInput"
                            name="description"
                            onChange={handleFormChange}
                            className="formDescription"
                            placeholder="Insert the description"
                          ></textarea>
                        </FormField>
                      </Grid>
                      <Grid item lg={12}>
                        <div className="slider">
                          <Slider
                            inputId={"imagesSlider"}
                            fieldName={"images"}
                            handleFormChange={handleFormChange}
                            isInfinite={imgSlider}
                            btnId={"imagesInput"}
                            images={images}
                            filesAccepted="image/jpeg, image/png, image/gif, image/svg, image/tiff, image/ico, image/dvu"
                          >
                            {images?.length > 0 ? (
                              images.map((image, index) => {
                                return (
                                  <React.Fragment key={image}>
                                    <img
                                      className="sliderItem"
                                      src={image}
                                      alt="upload"
                                      //key={image}
                                    ></img>
                                    <div className="removeBtn" onClick={() => removeImage(index)}>
                                      <AddIcon></AddIcon>
                                    </div>
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Slider>
                        </div>
                      </Grid>

                      {/* Video gallery */}
                      <Grid item lg={12}>
                        <div className="slider">
                          <Slider
                            inputId={"videosSlider"}
                            fieldName={"videos"}
                            isInfinite={vidSlider}
                            btnId={"videosInput"}
                            videos={videos}
                            handleFormChange={handleFormChange}
                            filesAccepted="video/mpeg, video/mp4, video/quicktime, video/wmv, video/avi, video/flu"
                          >
                            {videos?.map((video, index) => {
                              return (
                                <>
                                  <video key={video} className="sliderItem" alt="upload" controls>
                                    <source src={video}></source>
                                  </video>
                                  <div className="removeBtn" onClick={() => removeVideo(index)}>
                                    <AddIcon></AddIcon>
                                  </div>
                                </>
                              );
                            })}
                          </Slider>
                        </div>
                      </Grid>
                      {/* Audio */}
                      <div className="documentsSection">
                        <div className="documentsHeader">
                          <label className="sliderLabel">Audio</label>
                          <div className="buttonAudioLinkInput">
                            <label htmlFor={"audioInput"}>
                              <AddIcon />
                            </label>
                            <input
                              type="file"
                              key={Date.now()}
                              id="audioInput"
                              name="audio"
                              onChange={handleFormChange}
                              accept="audio/*"
                              multiple
                            />
                          </div>
                        </div>
                        <Grid container spacing={2} className="docsGrid">
                          {audio &&
                            audio.map((doc, index) => (
                              <Grid item lg={3} key={index}>
                                <div className="documentItem">
                                  <div className="documentIcon">
                                    <LibraryMusicIcon />
                                    <div className="fileExtension">{getFileExt(doc, "extension")}</div>
                                  </div>

                                  <div className="fileName">{getFileExt(doc, "name")}</div>

                                  <div className="docRemove" onClick={() => handleRemoveDoc(doc)}>
                                    <AddIcon></AddIcon>
                                  </div>
                                </div>
                              </Grid>
                            ))}
                        </Grid>
                      </div>

                      {/* Links */}
                      <div className="documentsSection">
                        <div className="documentsHeader addMarginLinks">
                          <label className="sliderLabel">Links</label>
                          <div className="buttonAudioLinkInput">
                            <label>
                              <div onClick={addLinkField} className="addPaddingLinks">
                                <AddIcon />
                              </div>
                            </label>
                          </div>
                        </div>
                        {links.map((field, index) => (
                          <div key={index} className="linkField">
                            <Grid container alignItems="center" sx={{ marginLeft: "25px" }}>
                              <Grid item lg={6} md={6} sm={6}>
                                <FormField inputId={`linkDescInput${index}`} fieldName="Link description">
                                  <input
                                    type="text"
                                    className="formInput"
                                    placeholder="Insert the link description"
                                    id={`linkDescInput${index}`}
                                    name="urlDescription"
                                    value={field.urlDescription}
                                    onChange={(e) => {
                                      const updatedLinks = [...links];
                                      updatedLinks[index].urlDescription = e.target.value;
                                      setLinks(updatedLinks);
                                    }}
                                  />
                                </FormField>
                              </Grid>
                              <Grid item lg={4} md={4} sm={4}>
                                <FormField inputId={`urlInput${index}`} fieldName="URL" sx={{ margin: 0 }}>
                                  <input
                                    type="url"
                                    id={`urlInput${index}`}
                                    name="url"
                                    value={field.url}
                                    onChange={(e) => {
                                      const updatedLinks = [...links];
                                      updatedLinks[index].url = e.target.value;
                                      setLinks(updatedLinks);
                                    }}
                                    className="formInput"
                                    placeholder="Insert the URL"
                                  />
                                </FormField>
                              </Grid>
                              <Grid item lg={2} md={2} sm={2}>
                                <div className="removeBtnLink" onClick={() => removeLinkField(index)}>
                                  <AddIcon />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item lg={12}>
                    <TranslationTab
                      setTranslationsSub={setTranslations}
                      typeContent={formData?.type}
                      englishTitle={formData?.title}
                      englishDescription={formData?.description}
                    ></TranslationTab>
                  </Grid>
                </Grid>
                <button type="submit" className="formBtn" onClick={(e) => handleFormSubmit(e)}>
                  Create new entry
                </button>
              </Container>
            )}
          </form>
          <ToastContainer></ToastContainer>
        </Grid>

        <Grid item lg={5} md={12} className="rightGrid" style={{ maxWidth: "42.5%" }}>
          <GoogleMap
            ref={mapRef}
            className="map"
            mapContainerStyle={{ height: "100vh", width: "100%" }}
            center={center}
            zoom={zoom}
            options={{
              gestureHandling: "none",
              streetViewControl: false,
              mapTypeControl: false,
            }}
          >
            {latitude?.length !== 0 && (
              <Marker
                className="marker"
                key="1"
                position={{
                  lat: parseFloat(latitude),
                  lng: parseFloat(longitude),
                }}
              ></Marker>
            )}
          </GoogleMap>
        </Grid>
      </Grid>
    </>
  );
}
