import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

import ConfirmationModal from "../modal/modal";

import EditIcon from "@mui/icons-material/EditRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";

import settings from "../../api/config";
import "./Table.css";

const TableComponent = ({ tableItems, setLoading, refreshMovieLocations }) => {
  const tableSections = ["Title", "Type", "Movie", "Location", "Published", "Translations", "Options"];
  const token = JSON.parse(localStorage.getItem("accessToken"));
  const [modalStatus, setModalStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [itemClicked, setItemClicked] = useState();

  const navigate = useNavigate();

  const modalController = (movieLocation) => {
    if (modalStatus === false) {
      setModalStatus(true);
      setMessage(
        `You will remove (${movieLocation.movie.OriginalTitle} - ${movieLocation?.location.City}, ${movieLocation?.location.Country})`
      );
      setItemClicked(movieLocation);
    } else {
      setModalStatus(false);
    }
  };

  const languages = {
    pt: "portuguese",
    sp: "spanish",
    cz: "czech",
    fi: "finnish",
    ro: "romanian",
    bg: "bulgarian",
    it: "italian",
    hr: "croatian",
    lt: "lithuanian",
    de: "deutch",
    fr: "french",
    en: "english",
    el: "greek",
    tr: "turkish",
  };

  const langsMap = new Map(Object.entries(languages));

  const getByValue = (map, lang) => {
    for (let [key, value] of map.entries()) {
      if (value === lang) return key;
    }
  };

  const getTranslations = (row) => {
    let movieTranslations = [];

    row?.AtlasTranslations.map((translation) => {
      const languageComponent = translation.__component;
      const lang = languageComponent.substring(languageComponent.indexOf(".") + 1);

      movieTranslations.push(getByValue(langsMap, lang));
    });

    if (movieTranslations?.length !== 0) {
      return <>{movieTranslations.sort().join(", ").toUpperCase()}</>;
    } else {
      return <>--</>;
    }
  };

  useEffect(() => {
    getTranslations();
  }, []);

  const deleteMovieLocation = (movieLocation) => {
    setLoading(true);

    axios({
      method: "delete",
      url: `${settings.BASE_URL}/movie-locations/${movieLocation?.id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        refreshMovieLocations();
      })
      .catch((error) => {
        setLoading(false);

        // Handle error.
        console.log("An error occurred:", error.response);
      });

    setModalStatus(false);
  };

  const getClickedEntry = (entry) => {
    localStorage.setItem("movie-location", JSON.stringify(entry));
  };

  return (
    <>
      <TableContainer className="mlTable">
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow className="tableTitles">
              {tableSections.map((section) => (
                <TableCell key={section} align="center">
                  {section}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {!tableItems || tableItems.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                  No entries were found.
                </TableCell>
              </TableRow>
            ) : (
              tableItems?.map((row) => (
                <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left">{row?.title}</TableCell>
                  <TableCell align="center">{row?.type}</TableCell>
                  <TableCell align="center">{row?.movie?.OriginalTitle}</TableCell>
                  <TableCell align="center">
                    {row?.location?.City}, {row?.location?.Country}
                  </TableCell>
                  <TableCell align="center">{row?.publish === false ? "No" : "Yes"}</TableCell>
                  <TableCell align="center">{getTranslations(row)}</TableCell>
                  <TableCell align="center" className="tableIcons">
                    <EditIcon
                      onClick={() => {
                        navigate("/update");
                        getClickedEntry(row);
                      }}
                    />
                    <DeleteIcon onClick={() => modalController(row)} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {modalStatus && (
        <ConfirmationModal
          isOpen={modalStatus}
          handleModalClose={modalController}
          modalMessage={message}
          action={deleteMovieLocation}
          modalIcon={"alert"}
          objectClicked={itemClicked}
        />
      )}
    </>
  );
};

export default TableComponent;
