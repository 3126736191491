import React, { useRef, useState, useEffect } from "react";

import { GoogleMap, Marker } from "@react-google-maps/api";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import { Grid, Container } from "@mui/material";
import BackArrow from "@mui/icons-material/ArrowBackIosNewRounded";
import AddIcon from "@mui/icons-material/AddRounded";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";

import Slider from "../../components/slider/Slider";
import DropzoneArea from "../../components/slider/DropzoneArea";
import TranslationTab from "../../components/tabs/UpdateTab";
import Loading from "../../components/loading/Loading";

import FormField from "../../components/field/Field";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { Switch, FormControlLabel } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";

import { isLoggedIn } from "../../utils/auth";

import "./UpdateLocation.css";
import settings from "../../api/config";

const UpdateLocation = () => {
  const mapRef = useRef(null);
  const navigate = useNavigate();

  const [token, setToken] = useState();

  const [images, setImages] = useState();
  const [videos, setVideos] = useState();
  const [audios, setAudios] = useState();
  const [links, setLinks] = useState();

  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [uploadedAudios, setUploadedAudios] = useState([]);

  const [objectClicked, setObjectClicked] = useState();
  const [loading, setLoading] = useState(false);
  const [translations, setTranslations] = useState([]);

  const [zoom, setZoom] = useState(9);
  const [center, setCenter] = useState({});

  const [formData, setFormData] = useState();

  const types = ["Film", "About the Film", "Experiences"];

  const getMedia = (mlMedia, setMedia) => {
    let mediaArray = [];

    //console.log("MEDIA", mlMedia);

    for (let i = 0; i < mlMedia.length; i++) {
      mediaArray.push(mlMedia[i]);
    }
    setMedia(mediaArray);
  };

  useEffect(() => {
    if (isLoggedIn()) {
      setToken(JSON.parse(localStorage.getItem("accessToken")));
    }

    const movieLocation = JSON.parse(localStorage.getItem("movie-location"));
    setObjectClicked(movieLocation);

    setCenter({
      lat: objectClicked?.location.lat,
      lng: objectClicked?.location.long,
    });

    getMedia(movieLocation?.Images, setImages);
    getMedia(movieLocation?.Videos, setVideos);

    // setAudios(movieLocation?.Audio);

    getMedia(movieLocation?.Audio, setAudios);

    setLinks(movieLocation?.Links);
    setUploadedImages(movieLocation?.Images);
    setUploadedVideos(movieLocation?.Videos);
    setUploadedAudios(movieLocation?.Audio);
    setFormData({
      title: movieLocation?.title,
      description: movieLocation?.description,
      publish: movieLocation?.publish,
    });
  }, []);

  const displayMedia = (mediaType, setMedia) => {
    const selectedMedia = Array.from(mediaType);

    //console.log("SELECTED MEDIA", selectedMedia);
    const mediaArray = selectedMedia.map((media) => {
      return URL.createObjectURL(media);
    });

    setMedia((previousMedia) => previousMedia?.concat(mediaArray));
  };

  const handleFormChange = (event) => {
    event.preventDefault();

    let name = "";
    let value = "";

    if (event.target.name === "title" || event.target.name === "description") {
      name = event.target.name;
      value = event.target.value;
    } else {
      name = event.target.getAttribute("name");
      value = event.target.files;
    }

    const newFormData = { ...formData };
    newFormData[name] = value;

    setFormData(newFormData);

    if (name === "images") {
      displayMedia(newFormData.images, setImages);
      setUploadedImages([...uploadedImages, ...newFormData.images]);
    } else if (name === "videos") {
      if (newFormData?.videos[0].size >= 200000000) {
        toast.error("Insert a video less than 200MB", {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }

      displayMedia(newFormData.videos, setVideos);
      setUploadedVideos([...uploadedVideos, ...newFormData.videos]);
    } else if (name === "audio") {
      const selectedAudio = Array.from(newFormData.audio);
      setUploadedAudios([...uploadedAudios, ...newFormData.audio]);
      if (audios) {
        setAudios((prevDocs) => prevDocs?.concat(selectedAudio));
      } else {
        setAudios(selectedAudio);
      }
    }
  };

  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    const newFormData = { ...formData, [name]: checked };

    setFormData(newFormData);
  };

  const [isInfinite, setIsInfinite] = useState();
  const [imgSlider, setImgSlider] = useState(false);
  const [vidSlider, setVidSlider] = useState(false);

  // console.log(formData);
  // console.log("imgs", images);
  // console.log("audio", audios);

  const changeSlider = (media, inf) => {
    if (media?.length > 1) {
      inf(true);
    } else {
      inf(false);
    }
  };

  useEffect(() => {
    changeSlider(images, setImgSlider);
  }, [images]);

  useEffect(() => {
    changeSlider(videos, setVidSlider);
  }, [videos]);

  // useEffect(() => {
  // }, [audios]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (objectClicked?.type !== "Film") {
      if (formData.title === "") {
        toast.error("Insert a Title!", {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }

      if (formData.description === "") {
        toast.error("Insert a description!", {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }

      if (images.length === 0) {
        toast.error("Select at least one image!", {
          position: "top-left",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }
    }

    setLoading(true);

    let copyTranslations = translations.map((object) => ({
      ...object,
    }));

    const filteredTranslations = copyTranslations.filter(
      (translation) =>
        translation.Tags.length !== 0 || translation.Documents.length !== 0
    );

    //console.log("FILTERED TRANSLATIONS: ", filteredTranslations);

    for (let i = 0; i < filteredTranslations.length; i++) {
      filteredTranslations[i].Tags = filteredTranslations[i].Tags.map((tag) => {
        return { TagName: tag };
      });

      if (filteredTranslations[i].Documents.length > 0) {
        const data = new FormData();
        let docHolder = [];
        let uploadedDocs = [];

        filteredTranslations[i].Documents.map((doc) => {
          //check if is a new file or an older one
          // console.log("TYPEOF: ", doc instanceof File);
          if (doc instanceof File) {
            docHolder.push(doc);
          } else {
            uploadedDocs.push(doc);
          }
        });

        // console.log("DOCHOLDER: ", docHolder);

        if (docHolder.length > 0) {
          for (let j = 0; j < docHolder.length; j++) {
            data.append("files", docHolder[j]);
          }

          let uploadDocuments = await axios.post(
            `${settings.BASE_URL}/upload/`,
            data
          );

          // console.log("UPLOADED DOCS: ", uploadDocuments.data);

          uploadDocuments.data.map((doc) => {
            uploadedDocs.push(doc);
          });

          filteredTranslations[i].Documents = uploadedDocs;

          // console.log("FILTERED DOCS: ", filteredTranslations[i].Documents);
        }
      }
    }

    console.log("filteredTransl", filteredTranslations);
    let updatedData = {
      AtlasTranslations: filteredTranslations,
      Images: [],
      Videos: [],
      Audio: [],
      title: formData.title,
      description: formData.description,
      publish: formData.publish,
      Links: links,
    };

    if (images.length > 0) {
      const data = new FormData();

      let imgHolder = [];

      const imagesToSubmit = removeContentWithId(uploadedImages);

      images.map((image) => {
        if (typeof image === "object") {
          imgHolder.push(image);
        }
      });

      if (imagesToSubmit.length !== 0) {
        console.log("Passa aqui");
        for (let i = 0; i < imagesToSubmit.length; i++) {
          data.append("files", imagesToSubmit[i]);
        }

        let uploadMedia = await axios.post(
          `${settings.BASE_URL}/upload/`,
          data
        );

        uploadMedia.data.map((uploads) => {
          imgHolder.push(uploads);
        });
      }

      setImages(imgHolder);
      updatedData.Images = imgHolder;
    } else {
      updatedData.Images = [];
    }

    if (videos.length > 0) {
      const data = new FormData();

      let vidHolder = [];

      const videosToSubmit = removeContentWithId(uploadedVideos);

      videos.map((video) => {
        if (typeof video === "object") {
          vidHolder.push(video);
        }
      });

      if (videosToSubmit.length !== 0) {
        for (let i = 0; i < videosToSubmit.length; i++) {
          data.append("files", videosToSubmit[i]);
        }

        let uploadMedia = await axios.post(
          `${settings.BASE_URL}/upload/`,
          data
        );

        uploadMedia.data.map((uploads) => {
          vidHolder.push(uploads);
        });
      }

      setVideos(vidHolder);
      updatedData.Videos = vidHolder;
    } else {
      updatedData.Videos = [];
    }

    if (audios.length > 0) {
      const data = new FormData();
      let audioHolder = [];

      const audiosToSubmit = removeContentWithId(uploadedAudios);

      audios.forEach((audio) => {
        if (typeof audio === "object" && !(audio instanceof File)) {
          audioHolder.push(audio);
        }
      });

      if (audiosToSubmit.length !== 0) {
        for (let i = 0; i < audiosToSubmit.length; i++) {
          if (audiosToSubmit[i] instanceof File) {
            data.append("files", audiosToSubmit[i]);
          }
        }

        let uploadMedia = await axios.post(
          `${settings.BASE_URL}/upload/`,
          data
        );

        uploadMedia.data.forEach((uploads) => {
          if (typeof uploads === "object" && !(uploads instanceof File)) {
            audioHolder.push(uploads);
          }
        });
      }

      setAudios(audioHolder);
      updatedData.Audio = audioHolder;
    } else {
      updatedData.Audio = [];
    }

    await axios
      .put(
        `${settings.BASE_URL}/movie-locations/${objectClicked.id}`,
        updatedData,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);

        navigate("/movie-locations");
      })
      .catch((error) => {
        setLoading(false);

        console.log("An error occurred:", error.response);
      });
  };

  useEffect(() => {
    // console.log("VIDEOS: ", videos);
  }, [videos]);

  const getFileExt = (file, request) => {
    let fileName = file.name;

    let name = fileName.split(".")[0];
    let extension = fileName.split(".")[1];

    if (request === "extension") {
      return <label>{extension}</label>;
    } else if (request === "name") {
      return <label>{name}</label>;
    }
  };

  //console.log("BACKGROUND", background);
  const addLinkField = () => {
    setLinks([...links, { urlDescription: "", url: "" }]);
  };
  const removeLinkField = (index) => {
    const updatedLinks = [...links];
    updatedLinks.splice(index, 1);
    setLinks(updatedLinks);
  };

  //REMOVE UPLOADS(Fazer refactor disto depois)-----------------------
  const removeUploadedImages = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
  };
  const removeUploadedVideos = (index) => {
    const updatedVideos = [...uploadedVideos];
    updatedVideos.splice(index, 1);
    setUploadedVideos(updatedVideos);
  };
  const removeUploadedAudio = (index) => {
    const updatedAudio = [...uploadedAudios];
    updatedAudio.splice(index, 1);
    setUploadedAudios(updatedAudio);
  };
  //------------------------------------------------------------------

  const removeContentWithId = (content) => {
    const filteredContent = content.filter((idx) => !idx._id);

    return filteredContent;
  };

  return (
    <>
      <Grid className="mainGrid" container>
        <Grid item lg={7} md={12}>
          <div className="header">
            <Container maxWidth="md">
              <span
                className="backAnchor"
                onClick={() => {
                  navigate("/movie-locations");
                }}
              >
                <BackArrow></BackArrow> <label>Go Back</label>
              </span>
              <h1>
                {objectClicked?.movie.OriginalTitle} -{" "}
                {objectClicked?.location.City},{" "}
                {objectClicked?.location.Country}
              </h1>
            </Container>
          </div>

          <form>
            {loading ? (
              <Loading />
            ) : (
              <Container maxWidth="md">
                <Grid container className="locationForm">
                  <Grid item lg={6} md={6} sm={6}>
                    <div className="formField">
                      <label htmlFor="titleInput" className="inputLabel">
                        Type
                      </label>
                      <input
                        type="text"
                        id="typeInput"
                        className="formInput disabled"
                        value={objectClicked?.type}
                        name="type"
                        disabled
                      ></input>
                    </div>
                  </Grid>

                  <Grid lg={6} md={6} sm={6}>
                    <FormField inputId={"publishInput"} fieldName={"publish"}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formData?.publish || false}
                            onChange={handleToggleChange}
                            color="primary"
                            name="publish"
                          />
                        }
                        label={formData?.publish === false ? "No" : "Yes"}
                      />
                    </FormField>
                  </Grid>

                  {objectClicked?.type !== "Film" ? (
                    <Grid item lg={6} md={6} sm={6}>
                      <div className="formField">
                        <label htmlFor="titleInput" className="inputLabel">
                          Title
                        </label>
                        <input
                          type="text"
                          id="titleInput"
                          className="formInput"
                          value={formData?.title}
                          name="title"
                          onChange={handleFormChange}
                          //disabled
                        ></input>
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item lg={6} md={6} sm={6}>
                    <div className="formField">
                      <label htmlFor="movieInput" className="inputLabel">
                        Movie
                      </label>
                      <input
                        type="text"
                        id="movieInput"
                        className="formInput disabled"
                        value={objectClicked?.movie.OriginalTitle}
                        disabled
                      ></input>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <div className="formField">
                      <label htmlFor="locationInput" className="inputLabel">
                        Location
                      </label>
                      <input
                        type="text"
                        id="locationInput"
                        className="formInput disabled"
                        value={`${objectClicked?.location.City}, ${objectClicked?.location.Country}`}
                        disabled
                      ></input>
                    </div>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6}>
                    <div className="formField">
                      <label htmlFor="coordinatesInput" className="inputLabel">
                        Coordinates
                      </label>
                      <input
                        type="text"
                        id="coordinatesInput"
                        className="formInput disabled"
                        value={`${objectClicked?.location.lat}, ${objectClicked?.location.long}`}
                        disabled
                      ></input>
                    </div>
                  </Grid>

                  {objectClicked?.type !== "Film" ? (
                    <Grid container>
                      <Grid item lg={12} md={12}>
                        <div className="formField">
                          <label
                            htmlFor="descriptionInput"
                            className="inputLabel"
                          >
                            Description
                          </label>
                          <textarea
                            type="text"
                            id="descriptionInput"
                            name="description"
                            onChange={handleFormChange}
                            className="formDescription"
                            value={formData?.description}
                            placeholder="Insert the description"
                          ></textarea>
                        </div>
                      </Grid>
                      <Grid item lg={12}>
                        <div className="slider">
                          <Slider
                            inputId={"imagesSlider"}
                            fieldName={"images"}
                            handleFormChange={handleFormChange}
                            images={images}
                            isInfinite={imgSlider}
                            btnId={"imagesInput"}
                            filesAccepted="image/jpeg, image/png, image/gif, image/svg, image/tiff, image/ico, image/dvu"
                          >
                            {images?.length > 0 ? (
                              images.map((image, index) => {
                                return (
                                  <React.Fragment key={image}>
                                    <img
                                      className="sliderItem"
                                      src={
                                        typeof image === "object"
                                          ? `${settings.BASE_URL}${image.url}`
                                          : image
                                      }
                                      alt="upload"
                                      //key={image}
                                    ></img>
                                    <div
                                      className="removeBtn"
                                      onClick={() => {
                                        setImages(
                                          images.filter((e) => e !== image)
                                        );
                                        removeUploadedImages(index);
                                      }}
                                    >
                                      <AddIcon></AddIcon>
                                    </div>
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Slider>
                        </div>
                      </Grid>
                      <Grid item lg={12}>
                        <div className="slider">
                          <Slider
                            inputId={"videosSlider"}
                            fieldName={"videos"}
                            handleFormChange={handleFormChange}
                            videos={videos}
                            isInfinite={vidSlider}
                            btnId={"videosInput"}
                            filesAccepted="video/mpeg, video/mp4, video/quicktime, video/wmv, video/avi, video/flu"
                            multiple
                          >
                            {videos?.map((video, index) => {
                              return (
                                <>
                                  <video
                                    key={video}
                                    className="sliderItem"
                                    alt="upload"
                                    controls
                                  >
                                    <source
                                      src={
                                        typeof video === "object"
                                          ? `${settings.BASE_URL}${video.url}`
                                          : video
                                      }
                                    ></source>
                                  </video>
                                  <div
                                    className="removeBtn"
                                    onClick={() => {
                                      setVideos(
                                        videos.filter((e) => e !== video)
                                      );
                                      removeUploadedVideos(index);
                                    }}
                                  >
                                    <AddIcon></AddIcon>
                                  </div>
                                </>
                              );
                            })}
                          </Slider>
                        </div>
                      </Grid>
                      <div className="documentsSection">
                        <div className="documentsHeader">
                          <label className="sliderLabel">Audio</label>
                          <div className="buttonAudioLinkInput">
                            <label htmlFor={"audioInput"}>
                              <AddIcon />
                            </label>
                            <input
                              type="file"
                              key={Date.now()}
                              id="audioInput"
                              //value={audios}
                              name="audio"
                              onChange={handleFormChange}
                              accept="audio/*"
                              multiple
                            />
                          </div>
                        </div>
                        <Grid container spacing={2} className="docsGrid">
                          {audios &&
                            audios.map((doc, index) => (
                              <Grid item lg={3} key={index}>
                                <div className="documentItem">
                                  <div className="documentIcon">
                                    <LibraryMusicIcon />
                                    <div className="fileExtension">
                                      {getFileExt(doc, "extension")}
                                    </div>
                                  </div>

                                  <div className="fileName">
                                    {getFileExt(doc, "name")}
                                  </div>

                                  <div
                                    className="docRemove"
                                    onClick={() => {
                                      setAudios(
                                        audios.filter((e) => e !== doc)
                                      );
                                      removeUploadedAudio(index);
                                    }}
                                  >
                                    <AddIcon></AddIcon>
                                  </div>
                                </div>
                              </Grid>
                            ))}
                        </Grid>
                      </div>

                      {/* Links */}
                      <div className="documentsSection">
                        <div className="documentsHeader addMarginLinks">
                          <label className="sliderLabel">Links</label>
                          <div className="buttonAudioLinkInput">
                            <label>
                              <div
                                onClick={addLinkField}
                                className="addPaddingLinks"
                              >
                                <AddIcon />
                              </div>
                            </label>
                          </div>
                        </div>
                        {links &&
                          links.map((field, index) => (
                            <div key={index} className="linkField">
                              <Grid
                                container
                                alignItems="center"
                                sx={{ marginLeft: "25px" }}
                              >
                                <Grid item lg={6} md={6} sm={6}>
                                  <FormField
                                    inputId={`linkDescInput${index}`}
                                    fieldName="Link description"
                                  >
                                    <input
                                      type="text"
                                      className="formInput"
                                      placeholder="Insert the link description"
                                      id={`linkDescInput${index}`}
                                      name="urlDescription"
                                      value={field.urlDescription}
                                      onChange={(e) => {
                                        const updatedLinks = [...links];
                                        updatedLinks[index].urlDescription =
                                          e.target.value;
                                        setLinks(updatedLinks);
                                      }}
                                    />
                                  </FormField>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                  <FormField
                                    inputId={`urlInput${index}`}
                                    fieldName="URL"
                                  >
                                    <input
                                      type="url"
                                      id={`urlInput${index}`}
                                      name="url"
                                      value={field.url}
                                      onChange={(e) => {
                                        const updatedLinks = [...links];
                                        updatedLinks[index].url =
                                          e.target.value;
                                        setLinks(updatedLinks);
                                      }}
                                      className="formInput"
                                      placeholder="Insert the URL"
                                    />
                                  </FormField>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                  <div
                                    className="removeBtnLink"
                                    onClick={() => removeLinkField(index)}
                                  >
                                    <AddIcon />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item lg={12}>
                  <TranslationTab
                    setTranslationsSub={setTranslations}
                    movieLocation={objectClicked}
                    englishTitle={formData?.title}
                    englishDescription={formData?.description}
                  ></TranslationTab>
                </Grid>
                <button
                  type="submit"
                  className="formBtn"
                  onClick={(e) => handleFormSubmit(e)}
                >
                  Update entry
                </button>
              </Container>
            )}
          </form>
          <ToastContainer></ToastContainer>
        </Grid>

        <Grid item lg={5} md={12} className="rightGrid">
          <GoogleMap
            ref={mapRef}
            className="map"
            mapContainerStyle={{ height: "100vh", width: "100%" }}
            center={
              objectClicked && {
                lat: objectClicked?.location.lat,
                lng: objectClicked?.location.long,
              }
            }
            zoom={zoom}
            options={{
              gestureHandling: "none",
              streetViewControl: false,
              mapTypeControl: false,
            }}
          >
            {objectClicked && (
              <Marker
                className="marker"
                key="1"
                position={{
                  lat: parseFloat(objectClicked?.location.lat),
                  lng: parseFloat(objectClicked?.location.long),
                }}
              ></Marker>
            )}
          </GoogleMap>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateLocation;
