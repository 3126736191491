import React, { useRef, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../../components/footer/Footer";
import "./Login.css";

import VideoBg from "../../assets/videos/loginBg.mp4";
import CinedLogo from "../../assets/img/cined-logo.png";

import axios from "axios";
import { isLoggedIn } from "../../utils/auth";

import settings from "../../api/config";

export default function App(props) {
  const [loginData, setLoginData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/movie-locations");
    }
  }, []);

  const handleChange = (event) => {
    event.preventDefault();

    const name = event.target.getAttribute("name");
    const value = event.target.value;

    const newData = { ...loginData };
    newData[name] = value;
    setLoginData(newData);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    setError("");
    setLoading("Loading...")

    axios
      .post(`${settings.BASE_URL}/admin/login`, {
        email: loginData.email,
        password: loginData.password,
      })
      .then((response) => {
        console.log(response.data.data.token);
        localStorage.setItem(
          "accessToken",
          JSON.stringify(response.data.data.token)
        );

        setError("");
        setLoading("");

        navigate("/movie-locations");
      })
      .catch((error) => {
        // Handle error.
        setError(error?.response?.data?.message ? error?.response?.data?.message : "An error occurred.");
        setLoading("");
        console.log("An error occurred:", error);
      });
  };

  return (
    <>
      <video autoPlay muted loop className="videoBg">
        <source src={VideoBg} type="video/mp4" />
      </video>

      <div className="videoOverlay"></div>

      <div className="loginContent">
        <img src={CinedLogo} alt="CinEd Logo" />

        <div className="welcomeMsg">
          <p>Welcome to</p>
          <span className="textAnimation">CinEd Atlas</span>
          <p>Back-Office</p>
        </div>

        <div className="loginMessage error">{error}</div>
        <div className="loginMessage warn">{loading}</div>

        <form className="loginForm" onSubmit={handleFormSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            placeholder="kai@doe.com"
            id="email"
            className="loginInput"
            name="email"
            onChange={handleChange}
            autoComplete="off"
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            placeholder="Password"
            id="password"
            className="loginInput"
            name="password"
            onChange={handleChange}
          />

          <input type="submit" className="loginBtn" value="Log In"></input>
        </form>
      </div>
      <Footer />
    </>
  );
}
