import React from "react";
import "./Field.css";

import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
const FormField = ({ inputId, fieldName, children }) => {
  return (
    <div className="formField">
      <label htmlFor={inputId} className="inputLabel">
        {fieldName}
      </label>
      {children}
    </div>
  );
};

export default FormField;
